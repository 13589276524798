<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">历思案件被评选上了中国法律服务网，被司法行政（法律服务）收录为典型案例啦！</div>
      <p class="time">2018.08.13</p>

      <p class="space_content titleText" style="font-size: 21px;text-indent:0"> 案件谜团：</p>
      <p class="space_content" style="text-indent:0">
        柯某驾驶的重型特殊结构货车在某市大道的灯杆路段中，与王某驾驶的两轮普通摩托车发生交通事故，造成车损及王某当场死亡的损害后果。当地警方特委托福建历思司法鉴定所对王某的尸体进行检测。福建历思司法鉴定所接到委托后对王某的尸体进行检验，并抽取其心胞血为检材进行检测分析，发现王某血液中含有乙醇成分，那么，问题来了，那王某是不是属于酒驾呢，柯某的审判是不是可以减轻刑罚呢？
      </p>
      <p class="space_content" style="text-indent:0">
        可是，经过警方调查，王某生前明明没有喝酒，身故后在血液中怎么会检测出乙醇呢？
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/15-1.png')">
      </div>

      <p class="space_content titleText" style="font-size: 21px;text-indent:0"> 谜团解析：</p>
      <p class="space_content" style="text-indent:0">
        福建历思司法鉴定所将王某心胞血的检材采用顶空气相色谱法进行检测，采用内标法进行定量。对检材中待测组分在气相色谱仪上的响应值和标准溶液响应值进行线性对应关系分析，求得待测组分的含量。
      </p>
      <p class="space_content" style="text-indent:0">
        根据GA/T 1073-2013《生物样品血液、尿液中乙醇、甲醇、正丙醇、乙醛、丙酮、异丙醇和正丁醇的顶空-气相色谱检验方法》标准，两次进样结果相对相差不超过10%（有凝血块的血样不得超过15％），定量数据可靠，结果按两次进样结果的平均值计算。经计算乙醇含量为20.96mg/100mL，正丙醇含量为1.90 mg/100mL。
      </p>
      <p class="space_content" style="text-indent:0">
        根据以上的检测分析，可以得出鉴定的结果，王某血样中的乙醇全部系血样腐败产生。
      </p>

      <p class="space_content titleText" style="font-size: 21px;text-indent:0">谜底揭晓：</p>
      <p class="space_content" style="text-indent:0">
        福建历思司法鉴定所对交通事故案中当事人王某血液中乙醇、正丙醇含量的法医毒物鉴定的结果：
      </p>
      <p class="space_content" style="text-indent:0">
        王某的腐败血样生成乙醇的同时，平行产生正丙醇。乙醇含量低于正丙醇含量的20倍以内，所以得出的鉴定结果，是王某血液中的乙醇含量全部系血液腐败产生。并且出具了司法鉴定意见书作为案件办理的证据。
      </p>
      <p class="space_content" style="text-indent:0">
        警方根据福建历思司法鉴定所提供的司法鉴定意见书，此次的交通事故王某不属于酒驾，对柯某将会依法追究其刑事责任。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/15-2.png')">
      </div>
      <p class="space_content" style="text-indent:0">
        此案例评选上了中国法律服务网，被司法行政（法律服务）作为典型的案例收录在案例库中了！
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/14'"><p class="ellipsis1">厦门公安局集美分局王局长一行莅临福建历思司法鉴定所调研</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/16'"><p class="ellipsis1">历思利用毛发检测，高新技术打破海南禁毒“滞案”僵局！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
